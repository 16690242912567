
<template>
    <router-view/>
</template>

<script setup>

function init() {
  FB.api(
      '/l214.animaux',
      {"fields":"fan_count"},
      function(response) {
        // alert(response.fan_count);
      }
  );

    FB.AppEvents.logPageView();


    
}

window.fbAsyncInit = function() {
  FB.init({
    appId      : process.env.VUE_APP_FACEBOOK_CLIENT_ID,
    xfbml      : true,
    version    : 'v2.5'
  });

  init();
};

(function(d, s, id){
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {return;}
  js = d.createElement(s); js.id = id;
  js.src = "//connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

</script>

