
export const ROUTES = {
    ATTENDEE:"attendee",
    HOME : "home",
    ABOUT : "about",
    CONTACT_US:'contactUs',
    BOOKING_SUCCESS:'bookingSuccess',
    ABOUT_US:"aboutUs",
    CULTURE_INFO:"cultureInfo",
    CATEGORY_EVENT_INFO:"categoryEventInfo",
    CITY_EVENTS_INFO:"cityEventsInfo",
    HOW_IT_WORKS:"howItWorks",
    PREVIOUS_EVENT:"previousEvent",
    CURRENT_EVENTS:"currentEvents",
    PAST_EVENTS:"pastEvents",
    ORGANIZER_PUBLIC_PROFILE:"organizerPublicProfile",
    LEADERSHIP_DEVELOPMENT:"leadershipDevelopment",
    REQUEST_PRIVATE_EVENT:"requestPrivateEvent",
    TERMS_CONDITIONS:"termsConditions",
    SUBMIT_INQUIRY:"submitInquiry",
    WELCOME:"welcome",
    CORPORATION_DEI:"corporationDei",
    AMERICAN_EXPLORER:"americanExplorer",
    ORGANIZER_INFORMATION:"organizerInformation",
    FAQS:"faqs",
    CULTURAL_ORGANIZER_PACKAGE:"culturalOrganizerPackages",
    CULTURE : "culture",
    CULTURE_DETAIL:"cultureDetail",
    JAPAN : "japan",
    PROFILE : "profile",
    SETTINGS : "settings",
    SEARCH : "search",
    ORGANIZATION : "organizationProfile",
    ORGANIZER:"organizerProfile",
    ADD_EVENTS : "addEvents",
    MANAGE_EVENTS : "manageEvents",
    EVENTS : "events",
    BROWSE_EVENTS : "browseEvents",
    EVENTS_LIST : "eventsList",
    EVENT : "singleEvent",
    TICKETS : "tickets",
    EDITPROFILE : "editProfile",
    FAVOURITES : "favourites",
    FOLLOWING_LIST:"followingList",
    TICKET_ORDERS : "ticketOrders",
    PRIVACY_POLICY : "privacyPolicy",
    CHECKOUT: "checkout",
    PAYMENTS: "payments",
    CARD_PAYMENT: "cardPayment",
    DELETE_ACCOUNT_REQUEST:"deleteAccountRequest",
    BECOMEAMBASSADOR:"becomeAmbassador",
    WHAT_IN_THE_CITY:"whatInTheCity",
    EXPLORE_CITY:"exploreCity",
    EMAIL_VERIFICATION:"emailVerification",
    EMAIL_VERIFIED:"emailVerified",
    LOGIN_AS_USER:"loginAsUser",
    JOIN_COMMUNITY:"joinCommunity",
    LOGIN:"login",
    REGISTER:"register",
    FORGOT_PASSWORD:"forgotPassword",
    BROWSE_EVENTS_LIST:"browseEventList",
    SHARE_YOUR_EVENT:"shareYourEvent",
    BLOGS:"blogs",
    BLOG_DETAIL:"blogDetail",
    BLOG_LIST:"blogList"
}
